/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import './Sidebar.css';
import logo from '../../images/jolders-logo.webp';
import { NavLink } from 'react-router-dom';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connectWallet } from '../../store/reducer/web3_reducer';
import ConnectModal from '../../components/Modal';

function Sidebar(props) {
	const [modalShow, setModalShow] = useState(false);
	const dispatch = useDispatch();
	const { connected } = useSelector((state) => state.web3);
	const [tab, setTab] = useState(null);

	useEffect(() => {
	}, []);
	return (
		<>
			<div className='sidebar-wrapper'>
				<div className='Sidebar_head__1Z76C'>
					{
						<a href='http://jolders.com/' target="_blank">
							<img src={logo} alt='logo' className='Sidebar_logo__3TLMd' />
						</a>
					}

					<div className='Sidebar_wallet__eXx60'>
						<button
							style={{ backgroundColor: '#7231da' }}
							className=' Sidebar_buttonConnect__small Button_button__17x3e Sidebar_buttonConnect__3toNv Button_buttonSmall__3-WQV Button_buttonBlue__3LvNm'
							onClick={
								connected
									? () => {
										setModalShow(true);
									}
									: () => {
										dispatch(connectWallet());
									}
							}
						>
							<span>Connect Wallet</span>
						</button>
					</div>
					<ConnectModal show={modalShow} onClose={() => setModalShow(false)} />
				</div>
				<div className='sidebar-content'>
					<nav>
						<div className='Sidebar_items__1Hqtp'>
							<div className="Sidebar_item__rJc2e Sidebar_itemActive__11Rxt">
                            <NavLink
								className="Sidebar_link__3B0JM"
								to="/dashboard/startups"
								activeClassName="Sidebar_link__3B0JM_active"
								onClick={() => props.getToggleStatus(false)}>

                                <div className='img-white-logo'>
										<div>
											<img src="./assets/images/crowdfunding.png" />
										</div>
										<div>
											<p>StartUps Market</p>
										</div>
									</div>
                            </NavLink>
							</div>
							<div className='Sidebar_item__rJc2e Sidebar_itemActive__11Rxt'>
								<NavLink
									className='Sidebar_link__3B0JM'
									to='/dashboard/staking'
									activeClassName='Sidebar_link__3B0JM_active'
									onClick={() => props.getToggleStatus(false)}
								>
									<div className='img-white-logo'>
										<div>
											<img src="./assets/images/staking.png" />
										</div>
										<div>
											<p><s>Farm & Stake</s></p>
										</div>
									</div>
								</NavLink>
							</div>
							<div className='Sidebar_item__rJc2e Sidebar_itemActive__11Rxt'>
								<a
									className='Sidebar_link__3B0JM'
									href="#"
									rel='noreferrer'
									onClick={() => {
										props.getToggleStatus(false);
									}}
								>
									<div className='img-white-logo'>
										<div><img src="./assets/images/raffles.png" /></div>
										<div><p><s>Secondary Market</s></p></div>
									</div>
								</a>
							</div>
						</div>
					</nav>
					<div className='Sidebar_bottom__3eeR3'>
						<div className='social-grp'>
							<a href='https://t.me/jolderschat/' target='_blank' rel='noreferrer'>
								<div>
									<img src="./assets/images/telegram.png" />
								</div>
							</a>
							<a href='https://www.tiktok.com/@joldersvc' target='_blank' rel='noreferrer'>
								<div>
									<img src="./assets/images/tiktok.png" />
								</div>
							</a>
							<a href='https://twitter.com/joldersvc' target='_blank' rel='noreferrer'>
								<div>
									<img src="./assets/images/twitter.png" />
								</div>
							</a>
							<a href='https://www.instagram.com/joldersvc' target='_blank' rel='noreferrer'>
								<div>
									<img src="./assets/images/instagram.png" />
								</div>
							</a>
						</div>
						<div className='guides'>
	
							<a href='http://jolders.com/' target='_blank' rel='noreferrer'>
								<div>
									Website
								</div>
							</a>
							<a href='http://docs.jolders.com/' target='_blank' rel='noreferrer'>
								<div>
									Documentation
								</div>
							</a>
							<a href='#' target='_blank' rel='noreferrer'>
								<div>
									<s>$JOL Token</s>
								</div>
							</a>
							<a href='mailto:Team@jolders.com' target='_blank' rel='noreferrer'>
								<div>
									Contact
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Sidebar;
